import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';


const LandingPage = () => {
  return (
      <>
      <div className="landing-page">

        <img src="landing_page_screenshot.png" alt="Example language learning app" className="landing-page-image" />
        <div className="landing-page-content">
          <h1 className="landing-page-heading">
            Try Verbius for free today!
          </h1>
          <p className="landing-page-description">
            Verbius is the first AI-based language-learning app. Have natural conversations to learn faster and get instant feedback to improve immediately.
          </p>
        </div>
        <div className="landing-page-footer">
          <div className="policy-link"><Link to="/privacy-policy" className="landing-page-link">Privacy Policy</Link></div>
          <div className="policy-link"><Link to="/eula-policy" className="landing-page-link">EULA</Link></div>
        </div>
      </div>
      </>
    );
  
  
};

export default LandingPage;
