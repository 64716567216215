import React from "react";
import eula from './eula.js'

class EULAPolicyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlContent: "",
    };
  }

  componentDidMount() {
//     fetch("test.html")
//       .then((response) => response.text())
//       .then((html) => {
//         this.setState({ htmlContent: html });
//         console.log(html);
//       })
//       .catch((error) => {
//         console.error(error);
//       });
    this.setState({ htmlContent:  eula})
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.state.htmlContent }} />;
  }
}

export default EULAPolicyPage;
